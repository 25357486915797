import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { SEO, Layout, PreviewCompatibleImage, Gallery } from "../components";
import Content, { HTMLContent } from "../components/Content";
import { Container, Wrapper } from "../ui/LayoutComponents";
import { PostTitle } from "../ui/Texts";
import { ImageContainer } from "../ui";

export const ActionPostTemplate = ({ title, image, gallery, slug, excerpt, content, contentComponent }) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <SEO article title={title} description={excerpt} url={slug} image={image.childImageSharp.fluid.src} />
      <Container>
        <PostTitle>{title}</PostTitle>
        <Wrapper>
          <ImageContainer marginB="2rem">
            <PreviewCompatibleImage loading="eager" imageInfo={image} />
          </ImageContainer>
          <PostContent className="markdown" content={content} />
          {gallery && <Gallery photos={gallery} />}
        </Wrapper>
      </Container>
    </>
  );
};

ActionPostTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  gallery: PropTypes.array,
  slug: PropTypes.string,
  excerpt: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
};

const ActionPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ActionPostTemplate
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        gallery={post.frontmatter.gallery}
        slug={post.fields.slug}
        excerpt={post.excerpt}
        content={post.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

ActionPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ActionPost;

export const pageQuery = graphql`
  query ActionPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 100)
      fields {
        slug
      }
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery {
          image {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
      }
    }
  }
`;
